<template>
  <q-page padding>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm"> {{ $t('deeplink_batch') }}</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/ " />
        <q-breadcrumbs-el :label="$t('deeplink_batch')" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />
    <GeneratorDeeplinkBatch />
  </q-page>
</template>

<script>
import GeneratorDeeplinkBatch from '../../components/deeplink/GeneratorDeeplinkBatch';

export default {
  components: { GeneratorDeeplinkBatch },
  name: 'Deeplink'
};
</script>

<style scoped></style>
