<template>
  <q-card flat class="justify-center">
    <q-form @submit.prevent="onFormSubmit">
      <q-card-section class="row q-col-gutter-md">
        <div class="col-6">
          <select-request
            :v-model="selectedChannel"
            :endpoint="`redirect?app=AFFILIATE&path=/api/get/affiliate/site_active/list/${user.affiliate_id}`"
            :label="$t('select_channel')"
            :rules="[isRequired]"
            @update="(value) => (selectedChannel = value === null ? null : value.id)"
            outlined
            clearable
            option-label="name"
            option-value="id"
          />
        </div>
        <div class="col-6">
          <q-select
            v-model="selectedCampaign"
            :disable="disable"
            @filter="filterCampaigns"
            :label="disable ? $t('no_campaigns_authorized') : $t('select_campaign')"
            :options="filteredCampaigns"
            :rules="[isRequired]"
            clearable
            outlined
            use-input
            clear-icon="mdi-close"
            option-label="campaign_name"
            option-value="campaign_id"
          />
        </div>

        <div class="col-12">
          <q-banner class="bg-info text-white q-mb-md" rounded>
            <q-item>
              <q-item-section top avatar>
                <q-icon name="mdi-information-outline" size="lg" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-weight-medium q-mb-md"> {{ $t('attention_to_file_format') }}</q-item-label>
                <q-item-label caption lines="3" class="text-white">
                  {{ $t('download_deeplink_batch_csv_example') }}
                  <a
                    href="https://afilio-v3.s3.amazonaws.com/template_deeplink.csv"
                    style="display: block"
                    class="q-mt-md"
                    >{{ $t('download_deeplink_batch_click') }}</a
                  >
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-banner>
        </div>

        <div class="col-12">
          <q-file
            v-model="uploadCsv"
            accept=".csv"
            clearable
            clear-icon="mdi-close"
            outlined
            :label="$t('csv_file')"
            :rules="[isUploadValid, isRequired]"
            @clear="(val) => (uploadedUrls = [])"
            @input="treatCsvInput"
          >
            <template v-slot:prepend>
              <q-icon name="attach_file" />
            </template>
          </q-file>
        </div>
        <div class="col-12 text-right">
          <q-btn color="positive" :label="$t('generate_deeplink')" no-caps unelevated type="submit" class="q-px-md" />
        </div>
      </q-card-section>
    </q-form>
  </q-card>
</template>

<script>
import SelectRequest from '../../widgets/inputsGlobal/SelectRequest.vue';
import AdvertiserService from '../../services/AdvertiserService';
import Deeplink from '../../services/Deeplink';

const CSV_HEADER = 'URL';

export default {
  name: 'GeneratorDeeplinkBatch',
  components: { SelectRequest },
  mixins: [AdvertiserService, Deeplink],
  data() {
    return {
      disable: true,
      selectedChannel: null,
      selectedCampaign: null,
      campaigns: [],
      filteredCampaigns: [],
      uploadCsv: null,
      uploadedUrls: [],
      resultData: null
    };
  },

  watch: {
    selectedChannel() {
      this.disable = true;
      this.campaigns = [];
      this.filteredCampaigns = [];
      this.selectedCampaign = null;
      this.getCampaignRelated(this.channel);
    }
  },

  methods: {
    filterCampaigns(val, update) {
      update(this.defaultFilter(val, 'filteredCampaigns', this.campaigns, 'name'));
    },

    async getCampaignRelated() {
      this.onLoading(true);
      try {
        const { data, status } = await this.listSiteCampaignsDeeplinkEnabled(this.selectedChannel);
        if (status === 200) {
          this.disable = data.length === 0;
          this.campaigns = data;
          this.filteredCampaigns = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    treatCsvInput(csv) {
      if (!csv) return;
      const csvReader = new FileReader();
      csvReader.readAsText(csv);

      csvReader.onload = () => {
        try {
          this.uploadedUrls = [];
          let csvLines = csvReader.result.split('\n').map((line) => line.replaceAll('\r', ''));
          console.log(csvLines);
          // 102 por conta do header e da linha em branco no final
          if (csvLines.length > 102) {
            throw new Error('deeplink_batch_over_100_lines_csv');
          }

          let csvHeaderLine = csvLines[0];
          if (csvHeaderLine != CSV_HEADER) {
            throw new Error('invalid_file_format');
          }

          let errLine = this.treatCsvUrlsLines(csvLines);
          if (errLine) {
            throw new Error(this.$t('invalid_url_at_line') + errLine + this.$t('from_file'));
          }
        } catch (error) {
          if (error.response && error.response.status === 500)
            this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
          else this.errorNotify(error);
          this.uploadCsv = null;
          this.uploadedUrls = [];
          return;
        }
      };
    },

    treatCsvUrlsLines(linesArr) {
      let csvLines;
      // Remove cabeçalho e linha vazia no final
      csvLines = linesArr.slice(1, linesArr.length - 1);
      if (csvLines.length == 0) throw new Error('empty_urls_file');

      let errLine;
      csvLines.forEach((line, index) => {
        if (!this.validURL(line)) {
          errLine = index + 2;
          return;
        } else {
          this.uploadedUrls.push(line);
        }
      });

      return errLine;
    },

    // eslint-disable-next-line
    isUploadValid(val) {
      return (
        (this.selectedChannel != null && this.selectedCampaign != null) ||
        this.$t('must_select_channel_and_campaign_to_upload_file')
      );
    },

    exportDeeplinkResponseToCSV(data) {
      let csvLinesData = data.map((val) => {
        return {
          url: val.url,
          url_encurtada: this.validURL(val.url_encurtada) ? val.url_encurtada : this.$t(val.url_encurtada)
        };
      });

      let csvHeaderColumns = [
        { name: 'URL', label: 'URL', align: 'center', field: 'url' },
        {
          name: this.$t('shortned_url').toUpperCase(),
          label: this.$t('shortned_url').toUpperCase(),
          field: 'url_encurtada'
        }
      ];

      this.exportTableCsv(csvLinesData, csvHeaderColumns, 'deeplinks-csv');
    },

    async onFormSubmit() {
      let requestData = {
        id_campaign: this.selectedCampaign.campaign_id,
        id_site: this.selectedChannel,
        urls: this.uploadedUrls
      };

      try {
        this.onLoading(true);
        const { data } = await this.createDeeplinkBatch(requestData);
        this.exportDeeplinkResponseToCSV(data);

        this.successNotify(this.$t('deeplink_batch_success'));
      } catch (err) {
        this.errorNotify(err);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style></style>
